import React from "react";
import { PageLayout, Background } from "@bluefin/components";
import { Grid, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class ServicesPage extends React.PureComponent {
  render() {
    return (
      <WebsiteLayout>
        <PageLayout
          hero={
            <Background
              columns={1}
              stackable={true}
              className={"services-hero"}
            >
              <Background.Image
                images={[
                  "https://fisherman.gumlet.io/public/securitysolutionsnetworkinc/hero.png",
                ]}
              />
            </Background>
          }
          subfooter={false}
          className={"services-page"}
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={12} textAlign={"center"}>
              <h2>Start with a Security Review</h2>
              <p>
                Using a comprehensive survey, we review what is in place now at
                each property in an affordable asset portfolio and how well
                those measures are working together to drive results. Among
                other things, we examine whether the outcomes of these measures
                are being tracked and what type of notifications management
                receives.
              </p>
              <h3>Typical security measures and systems we review include:</h3>
              <ul className={"alignLeft"} className={"bulleted-list"}>
                <li>Access control systems like gates and doors</li>
                <li>Video surveillance systems</li>
                <li>Uniformed security patrol</li>
                <li>Incident reporting and recordkeeping</li>
                <li>
                  Standard operating procedures with emphasis on notification
                  and follow-up
                </li>
                <li>Lease enforcement using criminal and civil enforcement</li>
                <li>
                  Stakeholder engagement including local agencies like police
                </li>
                <li>Owner reputation and liability considerations</li>
              </ul>
              <Button
                href={"https://calendly.com/multifamilysecurity"}
                as={"a"}
                target={"_blank"}
                content={"Schedule A Call"}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={12} textAlign={"center"}>
              <h2>Implement a Managed Security Program</h2>
              <p>
                Our innovations combined with practical approaches to crime
                prevention and response empower stakeholders to secure their
                apartment neighborhoods from the inside out. We help management
                know who is doing what and where, which informs how to disrupt
                those responsible for crime and disorder as well as what needs
                to be done to remove them from the community. In a
                custom-designed security program, we coordinate four overlapping
                security objectives for each community in an affordable
                portfolio.
              </p>
              <h3>Monitor, Respond, Resolve, Record</h3>
              <p className={"alignLeft"}>
                Using the MRRR approach a typical program may include some or
                all of the following measures from our network of providers:
              </p>
              <ul className={"alignLeft"} className={"bulleted-list"}>
                <li>
                  VSAAS, IP video surveillance service with remote access and
                  video storage
                </li>
                <li>
                  Uniformed security patrol using off-duty police officers and
                  security guards
                </li>
                <li>
                  Remote video system live monitoring coordinated with on-site
                  security patrol
                </li>
                <li>
                  Wrap around criminal incident tracking using open sources like
                  social media
                </li>
                <li>
                  Comprehensive incident reporting in digital, indexed
                  recordkeeping systems
                </li>
                <li>
                  Community outreach and stakeholder engagement advice and
                  support
                </li>
                <li>
                  Case management and litigation support for lease enforcement
                  actions
                </li>
                <li>
                  Security program continuity and capability support for
                  property managers
                </li>
                <li>Indexed Records Management and Recordkeeping</li>
              </ul>
              <p>
                Many security measures automatically generate security related
                information in the form of incident reports and video footage.
                We can manage this information for you.
              </p>
              <p>
                Most multifamily real estate companies still rely on paper
                records and are generally lagging other industries in making the
                transition to digital recordkeeping. Our security programs are
                built on a digital framework which captures and stores security
                related records in systems that allow for these records to be
                searched. Most crime and disorder problems in apartment
                communities are concentrated in a few locations and involve a
                small number of associated bad actors. A searchable database
                allows management to concentrate resources and focus efforts on
                the identified drivers of crime and disorder.
              </p>
              <Button
                href={"https://calendly.com/multifamilysecurity"}
                as={"a"}
                target={"_blank"}
                content={"Schedule A Call"}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={12} textAlign={"center"}>
              <h2>Litigation Support</h2>
              <p>
                Our clients have found that the information developed by our
                security programs can be critical in civil cases related to
                personal injury/premises liability as well as lease enforcement
                actions in landlord tenant court.
              </p>
              <p>
                We work closely with attorneys in responding to Interrogatories
                and Requests for Production of Documents in civil liability
                cases. The ability to produce good comprehensive and detailed
                records can reduce potential exposure to damage claims.
              </p>
              <p>
                Our case management services support successful lease
                enforcement and property recovery actions in landlord tenant
                court. We organize information and prepare witnesses for
                hearings about the removal of tenants whose criminal activity is
                contributing to disorder issues at properties.
              </p>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}
